import { useRouter } from "next/router";
import React, { useState, createContext } from "react";
import {
  CATEGORIES,
  CONDITIONS,
  DRIVETRAINS,
  FUELS,
  PROVINCES,
  TRANSMISSIONS,
} from "../../utils/constants";
import { TFilterOption } from "./types";
import { User } from "../../graphql/generated/graphql";

type TState = {
  open?: boolean;
  title?: string;
  description?: string;
  type?: string;
};

const categoryFilters = {
  id: "category",
  name: "Estilo",
  options: Object.values(CATEGORIES).map((category) => ({
    value: category.id,
    label: category.label,
    checked: false,
  })),
};

const conditionFilters = {
  id: "condition",
  name: "Condición",
  options: Object.values(CONDITIONS).map((condition) => ({
    value: condition.id,
    label: condition.label,
    checked: false,
  })),
};

const fuelFilters = {
  id: "fuel",
  name: "Combustible",
  options: Object.values(FUELS).map((condition) => ({
    value: condition.id,
    label: condition.label,
    checked: false,
  })),
};

const transmissionFilters = {
  id: "transmission",
  name: "Transmisión",
  options: Object.values(TRANSMISSIONS).map((condition) => ({
    value: condition.id,
    label: condition.label,
    checked: false,
  })),
};

const drivetrainFilters = {
  id: "drivetrain",
  name: "Tracción",
  options: Object.values(DRIVETRAINS).map((drivetrain) => ({
    value: drivetrain.id,
    label: drivetrain.label,
    checked: false,
  })),
};

const locationFilters = {
  id: "province",
  name: "Ubicación",
  options: Object.values(PROVINCES).map((province) => ({
    value: province.id,
    label: province.label,
    checked: false,
  })),
};

const initialFilters = {
  [categoryFilters.id]: categoryFilters,
  [conditionFilters.id]: conditionFilters,
  [fuelFilters.id]: fuelFilters,
  [transmissionFilters.id]: transmissionFilters,
  [drivetrainFilters.id]: drivetrainFilters,
  [locationFilters.id]: locationFilters,
};

export const allowedFilters = [
  "username",
  "category",
  "condition",
  "transmission",
  "province",
  "priceMin",
  "priceMax",
  "currency",
  "drivetrain",
  "fuel",
];

const buildFilters = (qsFilters: TQueryStringFilters) => {
  Object.keys(initialFilters).forEach((filterId) => {
    const qsFilter = qsFilters[filterId];
    const filterOptions = initialFilters[filterId].options.map((opt) => ({
      ...opt,
    }));
    filterOptions.forEach((option: TFilterOption) => {
      if (qsFilter && qsFilter.split(",").includes(option.value)) {
        option.checked = true;
      } else {
        option.checked = false;
      }
    });

    initialFilters[filterId] = {
      ...initialFilters[filterId],
      options: filterOptions,
    };
  });

  return initialFilters;
};

export type TContext = {
  state?: TState;
  toggleToast: (state?: TState) => void;
};

const initialValues: TContext = {
  state: {
    open: false,
  },
  toggleToast: () => null,
};

const Filters = createContext(initialValues);

const FiltersProvider = ({ children }: { children?: React.ReactNode }) => {
  const [state, setState] = useState({ open: false });

  const toggleToast = (state) => {
    setState(state);
  };

  const context = {
    state,
    toggleToast,
  };

  return <Filters.Provider value={context}>{children}</Filters.Provider>;
};

const useFilters = (query: TQueryString) => {
  const router = useRouter();
  const queryObject = router.query || query;

  const { search, page, sort, ...restFilters } = queryObject;

  function setFilter(e) {
    const filterId = e.target.name;
    const value = e.target.value;
    const { page, ...restQueryObject } = queryObject;
    const filterArray = restQueryObject[filterId]
      ? (restQueryObject[filterId] as string).split(",")
      : [];

    let newFilterArray = [];
    if (filterArray.includes(value)) {
      newFilterArray = filterArray.filter((f) => f !== value);
    } else {
      newFilterArray = [...filterArray, value];
    }

    const newQuery: TQueryString = {
      ...restQueryObject,
    };

    if (newFilterArray.length) {
      newQuery[filterId] = newFilterArray.join(",");
    } else {
      delete newQuery[filterId];
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }

  function clearFilters() {
    router.push(
      {
        pathname: router.asPath.split("?")[0],
        query: {},
      },
      undefined,
      { shallow: true }
    );
  }

  function setSort(e) {
    const sort = e.target.name;
    const { page, ...restQueryObject } = queryObject;

    const newQuery: TQueryString = {
      ...restQueryObject,
      sort,
    };

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }

  const filters = buildFilters(restFilters);

  return { filters, sort, setFilter, clearFilters, setSort };
};

export { Filters, FiltersProvider, useFilters };
