import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import { classNames } from "../utils/formatters";

type TPageButtonProps = {
  pageNumber?: number;
  dummy?: boolean;
  currentPage?: number;
  disabled?: boolean;
  onClick?: (p: number) => void;
};

function PageButton({
  pageNumber,
  dummy,
  currentPage,
  disabled,
  onClick,
}: TPageButtonProps) {
  const current = currentPage === pageNumber;

  function onPageClick() {
    onClick(pageNumber);
  }

  if (dummy) {
    return (
      <span
        className={classNames(
          "border-transparent  border-t-2 py-4 px-4 inline-flex items-center  font-medium",
          disabled ? "opacity-40" : ""
        )}
      >
        ...
      </span>
    );
  }
  return (
    <button
      className={classNames(
        "  hover:text-gray-700 hover:border-gray-300 border-t-2 py-4 px-4 inline-flex items-center  font-medium hover:bg-gray-100 disabled:opacity-40",
        current ? "border-orange-500 text-orange-500" : "border-transparent"
      )}
      onClick={onPageClick}
      disabled={disabled}
    >
      {pageNumber}
    </button>
  );
}

type TProps = {
  loading?: boolean;
  page: number;
  total?: number;
  pageSize?: number;
};

export default function ListPagination({
  loading,
  page,
  total = 1,
  pageSize = 1,
}: TProps) {
  const pages = total / pageSize < 1 ? 1 : Math.ceil(total / pageSize);

  const router = useRouter();

  function setPage(pageNumber) {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          page: pageNumber,
        },
      },
      undefined,
      { shallow: true, scroll: true }
    );
  }

  function prevPage() {
    setPage(page - 1);
  }

  function nextPage() {
    setPage(page + 1);
  }

  let buttons = [];
  if (pages > 7) {
    // Si la pagina actual es de las primeras 3 o ultimas 3 = 1,2,3,...,9,10,11
    if (page < 3 || page > pages - 2) {
      buttons = [
        <PageButton
          key={`page-1`}
          pageNumber={1}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton
          key={`page-2`}
          pageNumber={2}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton
          key={`page-3`}
          pageNumber={3}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton key={`page-dummy`} dummy disabled={loading} />,
        <PageButton
          key={`page-${pages - 2}`}
          pageNumber={pages - 2}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton
          key={`page-${pages - 1}`}
          pageNumber={pages - 1}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton
          key={`page-${pages}`}
          pageNumber={pages}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
      ];
    } else {
      // Si la pagina actual es mayor a 3 = 1,...,3,4,5,...11
      buttons = [
        <PageButton
          key={`page-1`}
          pageNumber={1}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton key={`page-dummy-1`} dummy disabled={loading} />,
        <PageButton
          key={`page-${page - 1}`}
          pageNumber={page - 1}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton
          key={`page-${page}`}
          pageNumber={page}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton
          key={`page-${page + 1}`}
          pageNumber={page + 1}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
        <PageButton key={`page-dummy-2`} dummy disabled={loading} />,
        <PageButton
          key={`page-${pages}`}
          pageNumber={pages}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />,
      ];
    }
  } else {
    for (let i = 0; i < pages; i++) {
      buttons.push(
        <PageButton
          key={`page-${i + 1}`}
          pageNumber={i + 1}
          currentPage={page}
          disabled={loading}
          onClick={setPage}
        />
      );
    }
  }

  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        <button
          onClick={prevPage}
          className="border-t-2 border-transparent py-4 pr-1 inline-flex items-center  font-medium  hover:text-gray-700 hover:border-gray-300 disabled:opacity-40"
          disabled={page <= 1 || loading}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Anterior
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {!loading ? buttons : null}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        <button
          onClick={nextPage}
          className="border-t-2 border-transparent py-4 pl-1 inline-flex items-center  font-medium  hover:text-gray-700 hover:border-gray-300 disabled:opacity-40"
          disabled={page === pages || loading}
        >
          Siguiente
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
}
