import { useRouter } from "next/router";
import CurrencyToggle from "./CurrencyToggle";

export default function CurrencyFilter() {
  const router = useRouter();
  const queryObject = router.query;
  const { currency } = queryObject;

  function setCurrency(e) {
    e.preventDefault();

    const { priceMin, priceMax, ...restQueryObject } = queryObject;

    router.push(
      {
        pathname: router.pathname,
        query: { ...restQueryObject, currency: e.target.id },
      },
      undefined,
      { shallow: true }
    );
  }

  return <CurrencyToggle currency={currency as string} onClick={setCurrency} />;
}
