import { Menu, Transition } from "@headlessui/react";
import { ArrowsUpDownIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { SORT_KEYS } from "../utils/constants";
import { classNames } from "../utils/formatters";
import { useFilters } from "../hooks/filters/useFilters";

const sortOptions = {
  // { name: "Most Popular", href: "#", current: true },
  // { name: "Best Rating", href: "#", current: false },
  [SORT_KEYS.LATEST]: {
    name: "Más recientes",
    id: SORT_KEYS.LATEST,
    current: false,
  },
  [SORT_KEYS.LOWEST]: {
    name: "Precio ascendente",
    id: SORT_KEYS.LOWEST,
    current: false,
  },
  [SORT_KEYS.HIGHEST]: {
    name: "Precio descendente",
    id: SORT_KEYS.HIGHEST,
    current: false,
  },
};

export default function SortDropdown({ className, query }) {
  const { sort, setSort } = useFilters(query);

  return (
    <Menu as="div" className={classNames("relative text-left", className)}>
      <div>
        <Menu.Button
          className={
            "inline-flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500"
          }
        >
          <ArrowsUpDownIcon
            className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          {/* <span className="hidden sm:inline">Ordenar por: </span> */}
          <span className="font-medium sm:font-normal ml-1">
            {sortOptions[sort as string]?.name ||
              sortOptions[SORT_KEYS.LATEST].name}
          </span>
          <ChevronDownIcon
            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {Object.values(sortOptions).map((option) => (
              <Menu.Item key={option.name}>
                {({ active }) => (
                  <button
                    name={option.id}
                    onClick={setSort}
                    className={classNames(
                      "w-full text-left flex items-center",
                      sort === option.id
                        ? "font-medium text-gray-900"
                        : "text-gray-500",
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {option.name}
                    {sort === option.id ? (
                      <span className="text-orange-500 ml-1">&#8226;</span>
                    ) : null}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
