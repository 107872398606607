import { CURRENCY_KEYS } from "../utils/constants";
import { classNames } from "../utils/formatters";

export default function CurrencyToggle({
  currency,
  large,
  onClick,
}: {
  currency: string;
  large?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  return (
    <span className="relative z-0 inline-flex rounded-md shadow-sm">
      <button
        type="button"
        id={CURRENCY_KEYS.CRC}
        onClick={onClick}
        className={classNames(
          currency === CURRENCY_KEYS.CRC || !currency
            ? "text-orange-500 bg-gray-50"
            : "text-gray-500 bg-white",
          "relative inline-flex items-center rounded-l-md border border-gray-300  font-medium  hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500",
          large ? "text-lg px-4 py-1" : "text-sm px-2 py-1"
        )}
      >
        ₡
      </button>
      <button
        type="button"
        id={CURRENCY_KEYS.USD}
        onClick={onClick}
        className={classNames(
          currency === CURRENCY_KEYS.USD
            ? "text-orange-500 bg-gray-50"
            : "text-gray-500 bg-white",
          "relative inline-flex items-center rounded-r-md border border-l-0 border-gray-300  font-medium  hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500",
          large ? "text-lg px-4 py-1" : "text-sm px-2 py-1"
        )}
      >
        $
      </button>
    </span>
  );
}
