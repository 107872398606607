import { Fragment, useState } from "react";
import { useFilters } from "../../hooks/filters/useFilters";
import { User } from "../../graphql/generated/graphql";
import FilterDropdown from "../FilterDropdown";
import PriceFilterDropdown from "../PriceFilterDropdown";
import YearFilterDropdown from "../YearFilterDropdown";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SearchField from "../../components/SearchField";
import PriceFilter from "../PriceFilter";
import YearFilter from "../YearFilter";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/solid";
import SortDropdown from "../SortDropdown";

export default function Filters({
  query,
  slug,
}: {
  query: TQueryString;
  watchedUser?: User;
  slug?: string;
}) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const { filters, setFilter, clearFilters } = useFilters(query);

  return (
    <>
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Filtros</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <div className="mt-4 border-t border-gray-200">
                  <div className="px-4 py-2">
                    <SearchField sidebar pathname={slug ? `/${slug}` : null} />
                    <PriceFilter />
                    <YearFilter />
                  </div>
                  {Object.values(filters).map((section) => (
                    <div
                      key={section.id}
                      className="border-t border-gray-200 px-4 py-4"
                    >
                      <h3 className="font-medium text-gray-900">
                        {section.name}
                      </h3>
                      <div className="pt-3">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-mobile-${section.id}-${optionIdx}`}
                              name={section.id}
                              defaultValue={option.value}
                              type="checkbox"
                              defaultChecked={option.checked}
                              className="h-4 w-4 border-gray-300 rounded text-orange-600 focus:ring-orange-500 text-input"
                              onClick={setFilter}
                            />
                            <label
                              htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="mt-3 flex items-center flex-wrap justify-between sm:justify-end">
        <FilterDropdown
          label="Transmisión"
          filters={filters.transmission}
          setFilter={setFilter}
        />
        <FilterDropdown
          label="Ubicación"
          filters={filters.province}
          setFilter={setFilter}
        />
        {/* <FilterDropdown
                label="Combustible"
                filters={filters.fuel}
                setFilter={setFilter}
              />  */}
        <FilterDropdown
          label="Estilo"
          filters={filters.category}
          setFilter={setFilter}
        />
        <FilterDropdown
          label="Condición"
          filters={filters.condition}
          setFilter={setFilter}
        />
        <PriceFilterDropdown />
        <YearFilterDropdown />
        <FilterDropdown
          label="Tracción"
          filters={filters.drivetrain}
          setFilter={setFilter}
        />
        <div>
          <button
            type="button"
            className="mt-2 mr-2 inline-flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500"
            onClick={() => setMobileFiltersOpen(true)}
          >
            <AdjustmentsVerticalIcon
              className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span className="hidden sm:inline">Todos los filtros</span>
            <span className="inline sm:hidden">Filtros</span>
          </button>
          <button
            type="button"
            className="mr-2 justify-center text-sm font-medium border border-red-400 text-red-400 hover:text-red-500 hover:border-red-500 shadow-sm mt-2 inline-flex rounded-full px-4 py-2"
            onClick={clearFilters}
          >
            <XMarkIcon
              className="flex-shrink-0 mr-1 h-5 w-5 text-red-400 group-hover:text-red-500"
              aria-hidden="true"
            />
            <span>Limpiar filtros</span>
          </button>
        </div>
        <SortDropdown className="sm:hidden inline-block mt-2" query={query} />
      </div>
    </>
  );
}
