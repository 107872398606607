import { getSlugMetadata } from "../utils/constants";
import {
  QueryGetVehiclesArgs,
  User,
  VehicleFiltersType,
} from "../graphql/generated/graphql";
import { allowedFilters } from "../hooks/filters/useFilters";

export function getVariables(
  queryObject,
  watchedUser?: User
): QueryGetVehiclesArgs {
  const {
    slug,
    username,
    search,
    page,
    priceMin,
    priceMax,
    currency,
    yearMin,
    yearMax,
    sort,
    model,
    gclid, // this is to ignore google adds query param
    ...filters
  } = queryObject;

  const pageNumber = page ? Number(page) : 1;
  const variables: QueryGetVehiclesArgs = {
    page: pageNumber,
    filters: {},
  };

  Object.keys(filters)
    .filter((filterId) => allowedFilters.includes(filterId))
    .forEach((filterId) => {
      variables.filters[filterId] = filters[filterId].split(",");
    });

  if (watchedUser) {
    variables.filters.username = slug;
  } else if (username) {
    variables.filters.username = username;
  }

  const slugMetadata = getSlugMetadata(slug);

  if (slugMetadata) {
    if (slugMetadata.type === "searchText") {
      variables.searchText = slugMetadata.filterValue as string;
    } else if (slugMetadata.type && slugMetadata.type !== "custom") {
      variables.filters[slugMetadata.type] = slugMetadata.filterValue;
    } else if (slugMetadata.type && slugMetadata.type === "custom") {
      variables.filters = slugMetadata.filterValue as VehicleFiltersType;
    }
  }

  if (model) {
    variables.filters.model = model;
  }

  if (sort) {
    variables.sort = sort;
  }

  if (priceMin) {
    variables.filters.priceMin = Number(priceMin);
  }

  if (priceMax) {
    variables.filters.priceMax = Number(priceMax);
  }

  variables.filters.currency = currency;

  if (yearMin) {
    variables.filters.yearMin = Number(yearMin);
  }

  if (yearMax) {
    variables.filters.yearMax = Number(yearMax);
  }

  if (search) {
    variables.searchText = search;
  }

  return variables;
}
